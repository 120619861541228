@import 'src/assets/css/theme/_variables.scss';
@import '../components/Home/home.scss';
@import url('https://fonts.cdnfonts.com/css/blade-runner-movie-font');

@font-face {
    font-family: 'BladeRunner';
    src: url('../assets/fonts/bladerunner-webfont.woff') format('woff');
    src: url('../assets/fonts/bladerunner-webfont.woff2') format('woff');
}

body {
    font-family: $primary-font;
    
    h3 ,h2 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    p {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #000;
    }
}

@media (max-width: 767px) {
 body h3 ,  body h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
}
body p {
    font-size: 14px;
}

}

