/***********footer*************/

footer {
  background: linear-gradient(180deg, #444 0%, #000 100%), #040909;
  padding: 40px 0 0;
  position: relative;

  h4 {
    font-family: 'BladeRunner';
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    /* 41.667% */
    letter-spacing: -0.24px;
    margin:5px auto;

    span {
      color: #E08300;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      display: block;
    }
  }

  ul {
    &.links_foot {
      list-style: none;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      padding: 0;

      li {
        a {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
          text-transform: uppercase;
          margin: 0 7px;
        }

        a:hover {
          text-decoration: none;
          color: #dc8105;
        }

        &:nth-of-type(2) a {
          border-left: 1px solid #ffff;
          border-right: 1px solid #fff;
          padding: 0 15px;
        }
      }
    }

    &.socails.links_foot li:nth-of-type(2) a {
      border: 0;
      padding: 0;
    }
  }

  .socails img {
    filter: invert(1);
  }

  .footer_bottom {
    background: #fff;
    position: relative;
    z-index: 9;

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;

      a {
        text-decoration: none;
        // color: #e08201;
        color : #000;
      }
    }
  }

  .footer_top {
    padding-bottom: 30px;
  }

  &:after {
    content: "";
    background: url(../../assets/img/footer_bg.png) no-repeat top right;
    width: 45%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-size: 100% 60%;
    right: 0;
  }

  h5 {
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    text-align: left;
    margin: 0;
    span {
      display: block;
      font-size: 35px;
      padding-left: 10px;
    }
  }

  .power_by {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
    width: 280px;
}
}
}

.footer_top,
footer .footer_bottom,
.footer_top {
  position: relative;
  z-index: 9;
}
.log_footer {
  text-align: center;
}

@media (max-width: 1200px) {
  footer h4 {
    font-size: 32px;
    text-align: center;
  }

  footer:after {
    background-size: 100% 80%;
  }

}

@media (max-width: 991px) { 
  footer {
    .socails img {
      width: 20px;
    }
  
    ul.links_foot li a {
      margin: 4px;
    }
  
    .footer_bottom p {
      font-size: 16px;
      line-height: 30px;
    }
  }
  
}

@media (max-width: 767px) {

  footer .footer_bottom p {
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0 10px !important;
  }

  .footer_top {
    text-align: center;
    padding-bottom: 10px;
  }

  footer {
    h4 {
      font-size: 32px;
      line-height: 25px;

      span {
        font-size: 14px;
      }
    }

    .socails img {
      width: 25px;
    }

    &:after {
      width: 100%;
      background-position: bottom center !important;
      background-size: 200%;
      height: 180px;
      bottom: 0;
      top: auto;
    }
    .power_by {
      img {
        width: 200px;
      }
    }
    ul.socails.links_foot {
      padding-bottom: 30px;
    }
  }

}

@media (max-width: 575px) {
  footer {   
  
    &:after {
      height: 210px;
    }
  
    .footer_top {
      padding-bottom: 30px;
    }
  }
  

}