header {
    nav.navbar {
      background-color: transparent;
      position: absolute;
      width: 100%;
      z-index: 1;
      left: 0;
      padding: 10px 0;
      top: -114px;
      transition: ease all .3s;
    }  
    nav.header_top {
        top: 0;
        left: 0;
        right: 0;
        ul.ml-auto.navbar-nav {
            flex-direction: row;
            a.nav-link {
                padding: 0 8px;
            }  
        }
    }
    .header.sticky.navbar , nav.header_top.other-header {
      position: fixed;
      z-index: 999;
      background: linear-gradient(360deg, #444 0%, #000 100%);
      box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.25);
      top: 0;  
      a.nav-link svg path {
          fill: #fff;
      }
      a.nav-link:hover svg path {
        fill: #E08300;
    } 
    h4 {
        color: #fff;
        visibility: visible;
        margin: 5px 0;
      }       
    }
    .head_footer h4 {
        span {
          color: #E08300;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          display: block;
          text-align: center;
        }  
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        font-family: 'BladeRunner';
        visibility: hidden;
      } 
  }
  nav.header_top.other-header.navbar {
    position: unset;
    background: linear-gradient(360deg, #444 0%, #000 100%);
    box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.25);
}
header nav.navbar img.logo{
  visibility: hidden;
  
}
header nav.header_top.other-header  img.logo {
  visibility: visible;
  filter: none;
}
header .header.sticky.navbar img.logo{
  visibility: visible;
}

  @media (max-width: 767px) {  
    header nav.header_top {
        display: none;
      }
      header .header.navbar ul.navbar-nav {
          display: none;
      }
      header nav.navbar img.logo {
          visibility: visible;
          filter: invert(1);
      }
      header .header.sticky.navbar img.logo {
        filter: none;
    }
      header nav.navbar {
        top: 0;
      }
      .head_footer h4 {
          visibility: visible !important;
      }
      header .header.sticky.navbar h4 {
          margin-bottom: 0;
      }
      header .header.sticky.navbar a.navbar-brand {
          margin: 0;
          width: 100%;
          text-align: center;
      }
      header .header.sticky.navbar {
          padding: 6px 0;
      }
      header a.navbar-brand {
          margin: 0;
          width: 100%;
          text-align: center;
      }
      nav.header_top.light.expand.other-header.navbar {
        display: block;
        position: relative;
        ul.ml-auto.navbar-nav {
          display: none;
      }
      
    }
    img.logo{
      width: auto;
    }
  }

  @media (max-width: 567px) { 
  .head_footer h4 {
    font-size: 30px !important;
  }
  img.logo {
    width: auto;
}
header .head_footer img.logo {
  filter: invert(1);
}
header .header.sticky.navbar img.logo {
  filter: none;
}

}
 
 