.banner_outer {
    position: relative;
    padding-top: 120px;
    &:after {
        background: url(../../assets/img/banner_bg_man.png) no-repeat right bottom;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        background-size: contain;
    }
    .banner_text {
        padding-bottom: 140px;
        position: relative;
        z-index: 9;
        .glitch {
            position: relative;
            color: black;
            font-size: 78px;
            font-style: normal;
            animation: glitch-skew 5s infinite linear alternate-reverse;
            font-family: $secondary-font;
            line-height: 58px;
            margin: 0;

        //     &::before {
        //         content: attr(data-text);   
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 100%;
        //         left: 2px;
        //         text-shadow: -2px 0 #47CF5C;
        //         clip: rect(44px, 450px, 56px, 0);
        //         animation: glitch-anim 5s infinite linear alternate-reverse;
        //     }

        //     &::after {
        //         content: attr(data-text);
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 100%;
        //         left: -2px;
        //         text-shadow: -2px 0 #47CF5C, 2px 2px #e08300;
        //         animation: glitch-anim2 1s infinite linear alternate-reverse;
        //     }
        // }

        // @keyframes glitch-anim {
        //     0% {
        //         clip: rect(96px, 9999px, 13px, 0);
        //         transform: skew(0.25deg);
        //     }

        //     5% {
        //         clip: rect(39px, 9999px, 25px, 0);
        //         transform: skew(0.56deg);
        //     }

        //     10% {
        //         clip: rect(77px, 9999px, 84px, 0);
        //         transform: skew(0.12deg);
        //     }

        //     15% {
        //         clip: rect(50px, 9999px, 43px, 0);
        //         transform: skew(1deg);
        //     }

        //     20% {
        //         clip: rect(75px, 9999px, 77px, 0);
        //         transform: skew(1deg);
        //     }

        //     25% {
        //         clip: rect(40px, 9999px, 49px, 0);
        //         transform: skew(0.05deg);
        //     }

        //     30% {
        //         clip: rect(43px, 9999px, 52px, 0);
        //         transform: skew(0.85deg);
        //     }

        //     35% {
        //         clip: rect(93px, 9999px, 70px, 0);
        //         transform: skew(0.23deg);
        //     }

        //     40% {
        //         clip: rect(85px, 9999px, 73px, 0);
        //         transform: skew(0.99deg);
        //     }

        //     45% {
        //         clip: rect(69px, 9999px, 22px, 0);
        //         transform: skew(0.93deg);
        //     }

        //     50% {
        //         clip: rect(54px, 9999px, 2px, 0);
        //         transform: skew(0.48deg);
        //     }

        //     55% {
        //         clip: rect(29px, 9999px, 25px, 0);
        //         transform: skew(0.45deg);
        //     }

        //     60% {
        //         clip: rect(7px, 9999px, 58px, 0);
        //         transform: skew(0.32deg);
        //     }

        //     65% {
        //         clip: rect(66px, 9999px, 51px, 0);
        //         transform: skew(0.4deg);
        //     }

        //     70% {
        //         clip: rect(28px, 9999px, 65px, 0);
        //         transform: skew(0.59deg);
        //     }

        //     75% {
        //         clip: rect(61px, 9999px, 68px, 0);
        //         transform: skew(0.4deg);
        //     }

        //     80% {
        //         clip: rect(92px, 9999px, 13px, 0);
        //         transform: skew(0.14deg);
        //     }

        //     85% {
        //         clip: rect(2px, 9999px, 10px, 0);
        //         transform: skew(0.7deg);
        //     }

        //     90% {
        //         clip: rect(38px, 9999px, 91px, 0);
        //         transform: skew(0.75deg);
        //     }

        //     95% {
        //         clip: rect(74px, 9999px, 53px, 0);
        //         transform: skew(0.38deg);
        //     }

        //     100% {
        //         clip: rect(36px, 9999px, 16px, 0);
        //         transform: skew(0.22deg);
        //     }
        // }

        // @keyframes glitch-anim2 {
        //     0% {
        //         clip: rect(50px, 9999px, 20px, 0);
        //         transform: skew(0.63deg);
        //     }

        //     5% {
        //         clip: rect(90px, 9999px, 67px, 0);
        //         transform: skew(0.26deg);
        //     }

        //     10% {
        //         clip: rect(47px, 9999px, 94px, 0);
        //         transform: skew(0.55deg);
        //     }

        //     15% {
        //         clip: rect(21px, 9999px, 75px, 0);
        //         transform: skew(0.88deg);
        //     }

        //     20% {
        //         clip: rect(84px, 9999px, 79px, 0);
        //         transform: skew(0.53deg);
        //     }

        //     25% {
        //         clip: rect(5px, 9999px, 29px, 0);
        //         transform: skew(0.45deg);
        //     }

        //     30% {
        //         clip: rect(66px, 9999px, 33px, 0);
        //         transform: skew(0.29deg);
        //     }

        //     35% {
        //         clip: rect(53px, 9999px, 52px, 0);
        //         transform: skew(0.45deg);
        //     }

        //     40% {
        //         clip: rect(24px, 9999px, 47px, 0);
        //         transform: skew(0.86deg);
        //     }

        //     45% {
        //         clip: rect(92px, 9999px, 1px, 0);
        //         transform: skew(0.32deg);
        //     }

        //     50% {
        //         clip: rect(45px, 9999px, 34px, 0);
        //         transform: skew(0.08deg);
        //     }

        //     55% {
        //         clip: rect(23px, 9999px, 81px, 0);
        //         transform: skew(0.51deg);
        //     }

        //     60% {
        //         clip: rect(42px, 9999px, 34px, 0);
        //         transform: skew(0.93deg);
        //     }

        //     65% {
        //         clip: rect(7px, 9999px, 68px, 0);
        //         transform: skew(0.13deg);
        //     }

        //     70% {
        //         clip: rect(18px, 9999px, 83px, 0);
        //         transform: skew(0.62deg);
        //     }

        //     75% {
        //         clip: rect(45px, 9999px, 76px, 0);
        //         transform: skew(1deg);
        //     }

        //     80% {
        //         clip: rect(59px, 9999px, 7px, 0);
        //         transform: skew(0.75deg);
        //     }

        //     85% {
        //         clip: rect(32px, 9999px, 58px, 0);
        //         transform: skew(0.11deg);
        //     }

        //     90% {
        //         clip: rect(90px, 9999px, 88px, 0);
        //         transform: skew(0.23deg);
        //     }

        //     95% {
        //         clip: rect(48px, 9999px, 93px, 0);
        //         transform: skew(0.34deg);
        //     }

        //     100% {
        //         clip: rect(92px, 9999px, 94px, 0);
        //         transform: skew(0.95deg);
        //     }
        // }

        // @keyframes glitch-skew {
        //     0% {
        //         transform: skew(1deg);
        //     }

        //     10% {
        //         transform: skew(0deg);
        //     }

        //     20% {
        //         transform: skew(-4deg);
        //     }

        //     30% {
        //         transform: skew(4deg);
        //     }

        //     40% {
        //         transform: skew(0deg);
        //     }

        //     50% {
        //         transform: skew(-1deg);
        //     }

        //     60% {
        //         transform: skew(1deg);
        //     }

        //     70% {
        //         transform: skew(1deg);
        //     }

        //     80% {
        //         transform: skew(-2deg);
        //     }

        //     90% {
        //         transform: skew(3deg);
        //     }

        //     100% {
        //         transform: skew(-3deg);
        //     }
        }

        h2 {
            color: #E08300;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 30px;
            
        }
        p i {
                font-weight: 600;
            }

    }    
}
.banner img.mobile-only-div {
    display: none;
}
.banner_img {
    position: relative;
    z-index: 9;
    text-align: right;
}
button.scroll-down-button {
position: absolute;
bottom: 40px;
left: 0;
right: 0;
margin: 0 auto;
width: 100px;
z-index: 9;
background: transparent;
border: 0;
}
button.scroll-down-button:active ,button.scroll-down-button:hover {
    background-color: transparent !important;
}
button.scroll-down-button:hover svg path {
    fill: #e08301;
}

/*********** welcome section *******/

.welcome {
    background: linear-gradient(90deg, #444 0%, #000 100%), linear-gradient(270deg, #6610F2 0%, #DC1D12 100%), linear-gradient(270deg, #E08300 0%, #FFA425 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    padding-top: 40px;  
    position: relative;
    z-index: 9;
    .welcome_main .welcome_text {
      h3 span {
        font-family: 'BladeRunner';
        color: #E08300;
        font-weight: 400;
      }  
      p {
        color: #fff;
        i {
            font-weight: 600;
        }
      }
      
    }
    img.mobile-only-div {
        display: none;
    }
    
  }
  
  /********** comunicate ***********/
  .comunicate {
    background: url(../../assets/img/grunge_background.png) no-repeat;
    background-size: cover;
    position: relative;
  
    .comunicate_one {
      margin-top: -170px;
    }
  }  

/******* bonus *********/
.welcome.bonus {
    padding-top: 121px;
    padding-bottom: 20px;
    margin-top: -170px;
    overflow: hidden;
    img {
        position: relative;
        right: -50px;
    }
    ul {
        margin: 60px 0 0;
        padding: 0;
        position: unset;
        text-align: left;
    }
    // .dot {
    //     background:  url(../../assets/img/square_gray.svg) no-repeat;
    //     display: inline-block;
    //     margin: 0 15px 0 0;
    //     width: 30px;
    //     height: 20px;
    //   }
    //   li:first-child{
    //     background:  url(../../assets/img/square_white.svg) no-repeat;
    //   }
    .slick-dots li {
        margin-right: 15px;
    }
      .slick-dots li button:before {
        background: url(../../assets/img/square_gray.svg) no-repeat;
        color: transparent;
        width: 30px;
        height: 20px;
        opacity: 1;
    }
    .slick-dots li.slick-active button:before{
        background:  url(../../assets/img/square_white.svg) no-repeat;
    }
    .slick-slider button.slick-arrow {
        display: none !important;
    }  
    
}

/**********get started***************/
.get_started {
    background: url(../../assets/img/grunge_background3.png) no-repeat;
    padding: 37px 0 0;
    background-size: cover;
    position: relative;
    margin-bottom: -120px;
    &:before {
      content: "";
      background: url(../../assets/img/Palette-Knife.png) no-repeat left center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;  
      bottom: 0;
      left: auto;
      background-size: contain;
    } 
    h3 span {
      font-size: 76px;
      font-style: italic;
      font-weight: 800;
      line-height: 76px;
      display: block;
      color: #E08300;
    }  
    .get_started_text {
      position: relative;
      margin-bottom: 170px;
    } 
    .row.play_store img {
        width: 183px;
    }       
    .get_started_img {
        position: relative;        
      } 
    button.dffg {
      width: 285px;
      height: 72px;
      background: #47CF5C;
      border: #47CF5C;
      font-family: Poppins;
      font-size: 24px;
      font-style: italic;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.24px;
      margin-bottom: 40px;      
      pointer-events: none;
    }
    button.dffg:hover {
        background: transparent;
        border: 1px solid #47CF5C;
        color: #47CF5C;
    } 

  }
  
  @media (max-width: 1200px) { 
    .banner_outer .banner_text .glitch {
        font-size: 60px;
    }
}
@media (max-width: 991px) { 
    .banner_outer .banner_text .glitch {
        font-size: 50px;
        line-height: 30px;
    }
    .welcome img.mobile-only-div {
        display: block;
    }    
    .welcome_text {
        text-align: left;
        float: left;
        max-width: 100%;       
    }
    .welcome_text img {

        float: right;
        width: 200px;
        position: relative;
        right: -32px;
    }    
    .welcome img.mobile-only-div {
        display: block;
        float: right;
        height: 350px;
        object-fit: cover;
        object-position: top;
    }.banner_img img {
        position: relative;
        right: 60px;
    }    
    body p {
        font-size: 16px;
    }
    .banner_outer .banner_text {
        padding-bottom: 90px;
    }
    button.scroll-down-button svg {
        width: 30px;
    }
    button.scroll-down-button {
        bottom: 20px;
    }
    .welcome .welcome_main .welcome_text p {
        margin-bottom: 90px;
    }.comunicate_img img {
        width: 100%;
    }
    .comunicate .comunicate_one {
        margin-top: -170px;
        padding: 40px 0;
    }
    .container {
        max-width: 100%;
        padding: 0 20px;
    }
    .welcome {
        overflow: hidden;
    }
    
}
@media (max-width: 767px) {
    .banner_outer .banner_text .glitch {
        font-size: 48px;
        line-height: 28px;
    }
    .welcome .welcome_main .welcome_text p {
        margin-bottom: 0;
    }
    .welcome img.mobile-only-div {
        height: 300px;
    }
    .welcome.bonus img {
        right: 0;
    }
    .welcome_main {
        flex-direction: row-reverse;
        align-items: start !important;
    }
    img {
        width: 100%;
    }    
    .banner_outer .banner_text h2 {
        font-size: 20px;
    }    
    .banner_outer .banner_text h2,.banner_outer .banner_text .glitch {
        display: none;
    }
    .banner_outer .banner_text {
        text-align: left !important;
        padding-bottom: 60px;
    }
    span.bold_text {
        font-weight: 700;
        margin-bottom: 10px;
        display: block;
        font-size: 20px;
    }
    .banner_outer .banner_text p i {
        font-weight: 700;
    }
    .banner_outer .banner_text p {
        font-size: 14px;
    }
    .banner_outer .banner_text a img {
        width: 140px;
        margin: 0 0px 20px;
    }
    .banner_outer button.scroll-down-button {
        display: none;
    }
    .comunicate{
    overflow: hidden;
    }
    .comunicate_img img {
        position: relative;
        right: -30px;
    }
    .comunicate .comunicate_one {
        margin-top: -120px;
    }
    .comunicate .comunicate_one:nth-of-type(2) .comunicate_img img {
        position: relative;
        left: -30px;
    }
    .welcome.bonus {
        padding-top: 60px;
        padding-bottom: 0;
        margin-top: -120px;
    }    
    button.scroll-down-button {
        display: none;
    }
    .welcome {
        padding: 60px 0;
    }
    .welcome.bonus ul {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .get_started .get_started_text h3 {
        font-size: 19px;
        margin-bottom: 13px;
    }
    .get_started h3 span {
        font-size: 36px;
        line-height: 36px;
    }
    .get_started button.dffg {
        font-size: 16px;
        padding: 13px 20px;
        width: auto;
        height:  auto;
        margin-bottom: 20px;
    }
    .get_started .get_started_text .row img {
        width: 140px;
        margin-bottom: 20px;
    }
    .get_started:before {
        background-position: top left 40%;
        background-size: cover;
    }
    .get_started .row.get_started_main {
        align-items: start !important;
    }
      
}

@media (max-width: 576px) {
    .welcome_text {
        max-width: 100%;
    }
    .welcome.bonus .slick-dots li button:before {
        width: 20px;
        height: 20px;
        background-size: contain !important;
    }
    .welcome.bonus .slick-dots li {
       margin-right: 5px;
    }
    .banner_img img {
        display: none;
    }
    .banner img.mobile-only-div {
        display: block;
    }
.banner_outer:after {
    background-size: 120%;
}
.banner_img {
    position: absolute;
    width: 80%;
    right: 0;
    bottom: 0;
}
.comunicate .comunicate_one {
    margin-top: -60px;
}
.welcome.bonus {
    margin-top: -60px;
}
.banner_img {
    width: 60%;
    top: 150px;
    z-index: 1;
  }
  .banner_img img {
    right: 0;
  }
  .welcome img.mobile-only-div {
    height: 240px;
    width: 140px;
  }
  .comunicate_text h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
}
.comunicate_text h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
}
.get_started button.dffg {
    width: 140px;
    padding: 13px 0;
    margin-bottom: 10px;
}
.get_started .get_started_text h3 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0px;
}
.get_started h3 span {
    font-size: 20px;
}
.get_started .get_started_text .row img {
    margin-bottom: 10px;
}

} 