.inner_pages {
    p {
    font-size: 16px;
    line-height: 24px;
    }
    strong {
        font-size: 20px;
    } 
    a {
        color: #E08300;
        word-break: break-word;
    }
   .get_started_text.innerpage {
    max-width: 590px;
    margin: 0 auto;
    h3 span {
        color: #E08300;
        display: block;
        font-size: 72px;
        font-style: italic;
        font-weight: 800;
        line-height: 72px;
    }
    }
    .form-control {
        border-radius: 2px;
        border: 1px solid #EAEAEA;
        background: #FAFAFA;
        padding: 10px 20px;
        color: #7A7A7A;
        &:focus {
            box-shadow: 0 0 0 0.25rem #e0830078;
            border-color: #e08300b3;
        }
    }
    textarea {
        height: 180px;
        resize: none;
    }
    span {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
    }
    button {
    width: 187px;
    height: 47px;
    background: #47CF5C;
    border-color: #47CF5C;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    line-height: 20px;
    margin-top: 40px;
    }
}


@media (max-width: 576px) {
    .inner_pages {
        h2 {
            text-align: left !important;
        }
        .get_started_text.innerpage h3 {
        font-size: 19px;
        span{
            font-size: 36px;
            line-height: 36px;
        } 
    }
    }
    }